import { render, staticRenderFns } from "./BulkUpload.vue?vue&type=template&id=6ea1492a&scoped=true&"
import script from "./BulkUpload.vue?vue&type=script&lang=js&"
export * from "./BulkUpload.vue?vue&type=script&lang=js&"
import style0 from "../css/boostrap.min.css?vue&type=style&index=0&lang=css&"
import style1 from "../css/styles.css?vue&type=style&index=1&lang=css&"
import style2 from "./BulkUpload.vue?vue&type=style&index=2&id=6ea1492a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ea1492a",
  null
  
)

export default component.exports