<template>
  <div>
    <main>
      <div
        class="main-section"
        :style="`background: url(${require('@/assets/img/Bitmap-bg-crop.jpg')})`"
      >
        <div class="container h-100">
          <a href="#" class="logo">
            <img :src="require('@/assets/img/logo-sm.png')" alt="" />
          </a>
          <form action="#">
            <div class="form-inner">
              <h2>
                Paste up to 1,000 ISRC codes here to find publishing data in
                seconds.
              </h2>
              <textarea v-model="codes"></textarea>
              <div v-if="lookupCodesStatusError" class="lookup-error">
                <span>No matches found</span>
              </div>
              <button
                @click.prevent="lookup"
                :disabled="lookupCodesStatusPending"
                class="default-button button"
              >
                {{ lookupCodesStatusPending ? "PROCESSING" : " LOOK UP" }}
              </button>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </main>
    <!-- main end -->
  </div>
</template>

<script>
import { fetchIsrcData } from "@/api/isrcApi";
import { API_STATUS } from "@/constants/apiStatus";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import {
  publisherDataComputed,
  setLookupResult,
} from "@/views/apps/services/publisherDataService";
import Footer from "../components/Footer";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      // codes: "USPM19101058 USPM19101059",
      codes: "",
      lookupCodesStatus: API_STATUS.IDLE,
    };
  },
  created() {
    this.API_STATUS = API_STATUS;
    localStorage.removeItem("purchase-login-email-sent");
  },
  computed: {
    ...apiStatusComputedFactory("lookupCodesStatus"),
    ...publisherDataComputed,
  },
  methods: {
    async lookup() {
      if (!this.codes) {
        alert("Please provide space separated list of codes");
        return;
      }
      const codes = this.codes
        .replaceAll(",", " ")
        .replaceAll('"', "")
        .replaceAll("'", "")
        .replaceAll("\n", " ")
        .split(" ")
        .map((code) => code.trim())
        .filter(Boolean);
      if (codes.length > 1000) {
        alert("Please provide maximum 1000 codes");
        return;
      }
      try {
        this.lookupCodesStatus = API_STATUS.PENDING;
        const result = await fetchIsrcData(codes);

        const { matchedCodesCount } = result;

        if (!matchedCodesCount) throw new Error("NO_ITEMS_FOUND");
        setLookupResult(result);
        this.lookupCodesStatus = API_STATUS.SUCCESS;
        // this.$router.push("/publisher-data");
        this.$router.push("/summary");
      } catch (error) {
        console.error(error);
        this.lookupCodesStatus = API_STATUS.ERROR;
      }
    },
  },
};
</script>
<style src="../css/boostrap.min.css"></style>
<style src="../css/styles.css"></style>
<style lang="scss" scoped>
.lookup-error {
  margin-top: 1rem;
}
@media (max-width: 1399px) {
  .main-section .container .logo {
    width: 250px;
  }
  .main-section .container h2 {
    font-size: 30px;
  }
  .main-section .container form .form-inner textarea {
    height: 103px;
    margin-top: 30px;
    font-size: 15px;
    padding: 7px 25px;
    line-height: 1.9;
    margin-top: 20px;
  }
  .main-section .container form {
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: 450px;
  }
  .default-button {
    font-size: 18px;
    height: 55px;
    padding: 0 27px;
  }
  .main-section .container form .form-inner .button {
    margin-top: 45px;
  }
  .main-section .container .footer-nav li {
    font-size: 15px;
    font-weight: 700;
  }
  .main-section {
    padding: 70px 0 60px;
  }
  .main-section .container form .form-inner textarea.small {
    height: 50px;
  }

  .main-section .data-show .ring svg {
    width: 330px;
  }
  .main-section .data-show {
    margin: 40px 0 60px;
  }

  .data-table .table-main table td {
    padding: 4px 30px 15px 30px;
  }
  .data-table .table-main table thead td {
    padding: 15px 30px 5px 30px;
  }
  .data-table .table-main table td:first-child {
    padding-left: 25px;
  }
}

@media (max-width: 1199px) {
  .data-table .table-main table thead td {
    font-size: 16px;
    padding: 10px 30px 6px 20px;
  }
  .data-table .table-main table td {
    font-size: 14px;
    padding: 4px 15px 8px 20px;
    font-weight: 400;
  }
  .data-table .table-main table td:first-child {
    padding-left: 15px;
  }
  .data-table .table-main table tbody td:first-child {
    border-right-width: 3px;
  }

  .data-table .head-part .about-search .data h4 {
    font-size: 30px;
  }
  .data-table .head-part .about-search .data span {
    font-size: 16px;
  }
  .data-table .head-part .about-search {
    gap: 35px;
  }
  .data-table .head-part .about-search .data {
    gap: 6px;
  }
  .data-table .head-part .search-box .input-group input[type="text"] {
    font-size: 17px;
    height: 42px;
    padding: 0 12px;
  }
  .data-table .head-part .search-filter .current-filter {
    font-size: 13px;
    padding: 4px 10px;
    height: 35px;
    gap: 3px;
  }
  .data-table .head-part .search-filter .current-filter button i {
    font-size: 15px;
  }
  .data-table .head-part {
    padding: 15px;
  }
}

@media (max-width: 991px) {
  .main-section .container h2 {
    font-size: 22px;
  }
  .default-button {
    font-size: 16px;
    height: 46px;
    padding: 0px 22px;
  }

  .main-section .data-show .ring svg {
    width: 220px;
  }
  .data-table .table-main table thead td {
    padding: 10px 0px 6px 12px;
  }
  .data-table .table-main table td {
    padding: 4px 0px 8px 12px;
  }
  .data-table .table-main .data-table-inner {
    min-width: 760px;
  }
  .data-table .table-main table tbody td:first-child {
    border-right: none;
  }
  .data-table .head-part .search-filter {
    width: 100%;
    padding-bottom: 10px;
  }
  .data-table .head-part .about-search {
    gap: 30px;
    width: 100%;
    justify-content: flex-start;
  }
  .before-data {
    margin: 50px 0 0;
  }
  .before-data h4 {
    font-size: 16px;
    gap: 10px;
  }
  .before-data h4 img {
    width: 25px;
  }

  .main-section .container .page-close {
    width: 20px;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 575px) {
  .main-section .data-show .ring svg {
    width: 160px;
  }
  .main-section .data-show .top {
    gap: 0;
  }

  .data-table .head-part .about-search .data {
    gap: 0;
    flex-direction: column;
  }
  .data-table .head-part .about-search .data h4 {
    margin-bottom: 0;
  }
  .data-table {
    margin-top: 10px;
  }
}

@media (max-width: 390px) {
  .main-section {
    padding: 40px 0 40px;
  }
  .before-data {
    margin: 30px 0 0;
  }
}
</style>